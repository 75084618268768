nbs-page-heading {
  ul.breadcrumb {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      font-style: normal;
      font-weight: normal;
      color: rgba(0,0,0,0.87);
      display: inline;
      font-size: 14px;
      line-height: 1em;
      letter-spacing: 0.25px;

      &:last-child {
        font-weight: 500;
      }

      + li:before {
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 18px;
        vertical-align: middle;
        padding: 4px;
        content: 'chevron_right';
        color: rgba(0,0,0,0.54);
      }
    }
  }

  .back-link {
    mat-icon {
      font-size: 18px!important;
      vertical-align: middle;
      padding: 0 4px 4px;
    }

    &:hover {
      cursor: pointer;

      .no-underline {
        text-decoration: none;
      }
    }
  }

  .breadcrumb-with-back {
    li:nth-child(2):before {
      display: none;
    }

    li:nth-child(1):after {
      content: '|';
      opacity: .38;
      padding: 8px;
    }
  }
}
