nbs-component-dialog {
  div.mat-mdc-dialog-content {
    color: rgba(0,0,0,.8) !important;
    margin: 0 -24px;
    padding: 0 24px !important;
  }

  div.mat-mdc-dialog-title {
    display: flex;
    margin: 0 0 20px;
    padding: 0;
    place-content: stretch space-between;

    h2 {
      color: rgba(0, 0, 0, 0.87) !important;
      margin: 0;
      width: 100%;
    }

    nbs-button {
      margin-top: -4px;
    }
  }

  div.mat-mdc-dialog-actions {
    justify-content: flex-end;
    padding: 16px 0;
  }
}
