nbs-field-search {
  .chip-list-wrapper {
    position: initial;
  }

  .form-field-wrapper {
    padding: 0.5em 0 0;
  }

  .inner-form-field {
    padding: 0;
  }

  .new-field-name {
    padding-right: 0.2em;
  }

  mat-chip-row {
    font-size: 0.9em!important;

    strong {
      padding-right: 0.2em;
    }

    ul {
      list-style: none;
      line-height: inherit;
      padding: 0;
      margin: 0;
      display: inline;

      li {
        display: inline;

        &:not(:last-child)::after {
          content: ' ';
        }
      }

      &.andSeparator li:not(:last-child)::after {
        content: ' AND ';

        html[lang="es"] & {
          content: ' y ';
        }
      }

      &.orSeparator li:not(:last-child)::after {
        content: ' OR ';

        html[lang="es"] & {
          content: ' o ';
        }
      }
    }
  }

  input {
    font-weight: normal !important;
    text-overflow: ellipsis !important;
  }

  .mat-mdc-form-field-icon-prefix {
    position: static;
  }
}
