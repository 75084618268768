mat-paginator {
  .mat-mdc-paginator-range-label {
    text-align: right;
    white-space: nowrap;
  }

  .mat-mdc-icon-button svg {
    vertical-align: middle;
  }

  mat-form-field.mat-mdc-form-field .mat-mdc-text-field-wrapper {
    margin-top: 0 !important;
  }

  @media screen and (max-width: 420px) {
    .mat-mdc-paginator-container {
      margin: 0;
      margin-left: auto;
      max-width: 320px;
      padding: 0;
    }

    .mat-mdc-paginator-range-actions {
      display: block;
    }

    .mat-mdc-paginator-range-label {
      display: block;
      margin: 8px 8px 0 0;
    }

    .mat-mdc-text-field-wrapper {
      margin: 0 !important;
    }
  }
}
