/* Added to resolve issues found after upgrading to Angular 16
    1. Selected in range dates are no longer highlighted
    2. Disabled next button color style is missing
*/
@use '@angular/material' as mat;
@use '../theme/color-palette';

.mat-calendar-body-in-range::before {
    background: mat.get-color-from-palette(color-palette.$nbs-primary, 100) !important;
    opacity: 0.25;
}

.mat-datepicker-content .mat-calendar-next-button :disabled, .mat-mdc-icon-button:disabled {
    color: var(--mdc-icon-button-disabled-icon-color) !important;
  }
