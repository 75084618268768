@use './theme/color-palette';
@use '@angular/material' as mat;

nbs-wait-dialog {
  .progress-container {
    margin: 2em 0;
  }
  
  .mat-mdc-dialog-title {
    margin: auto;
    text-align: center;
  }

  .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
    background-color: mat.get-color-from-palette(color-palette.$nbs-primary, 100);
  }
}
