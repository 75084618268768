@import 'csv.scss';
@import 'margins.scss';
@import '../mixins/scrollable.scss';

.left-text {
  text-align: left;
}

.center-text {
  text-align: center;
}

.right-text {
  text-align: right;
}

.center {
  width: 100%;
  margin: 0 auto;
}

.centered {
  margin: 0 auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.w100 {
  width: 100%;
}

.clickable:hover {
  cursor: pointer;
}

.scrollable {
  @include scrollable;
}

.scrollable-on-hover {
  @include scrollable-on-hover;
}
