nbs-session-end-page {
  .signout-message {
    padding: 10px;
    margin-bottom: 40px;
  }
  
  h2 .icon {
    color: #4D6F8B;
    vertical-align: middle;
    padding-right: 10px;
    font-size: 72px;
    width: 72px;
    height: 72px;
  }
  
}