nbs-search-filter {
  display: block;

  form {
    display: flex;
    flex-direction: row;

    .mdc-notched-outline__notch,
    .mdc-notched-outline--notched .mdc-notched-outline__notch {
      border-top-color: currentColor !important;
      padding-right: 0 !important;
      border-left-width: 0;
    }

    .left-form-field {
      flex: 1 1 100%;
      max-width: 35%;

      .mdc-notched-outline {
        background: rgba(0,0,0,.04);
      }

      .mdc-notched-outline__trailing {
        border-radius: 0;
      }

      &:not(:hover):not(.mat-focused) .mdc-notched-outline__trailing {
        border-right: 0;
      }

      &:hover, &.mat-focused {
        + .right-form-field .mdc-notched-outline__leading {
          border-left: 0;
        }

        .mdc-notched-outline__notch,
        .mdc-notched-outline--notched .mdc-notched-outline__notch {
          border-left: 0 !important;
        }
      }
    }

    .right-form-field {
      flex: 1 1 0%;
      max-width: 70%;

      .mdc-notched-outline__leading {
        border-radius: 0;

        .left-form-field:hover &, .left-form-field.mat-focused & {
          border-left: 0;
        }
      }

      .mat-input-element::placeholder {
        font-weight: normal;
        color: rgba(0,0,0,.42) !important;
        -webkit-text-fill-color: currentColor !important;
      }

      &:hover, &.mat-focused {
        .mdc-notched-outline__notch,
        .mdc-notched-outline--notched .mdc-notched-outline__notch {
          border-left: 0 !important;
        }
      }
    }

    .single-filter {
      flex: 1 1 0%;
      max-width: 100%;

      .mat-input-element::placeholder {
        font-weight: normal;
        color: rgba(0,0,0,.42) !important;
        -webkit-text-fill-color: currentColor !important;
      }
    }
  }

  .mat-mdc-form-field-infix {
    width: auto;
  }

  .display-none {
    display: none;
  }
}
