@use '@angular/material' as mat;
@import '../mixins/scrollable.scss';



// by default mat-form-field are full width of their container forcing them to stack one above the other
mat-form-field.mat-mdc-form-field {
  display: inline;
  font-size: 16px;

  .mat-mdc-form-field-flex {
    align-items: center;
    padding-right: 0.75em;
  }

  .mat-mdc-text-field-wrapper {
    margin-top: 0.75em;
    padding-right: 0px;
  }

  .mat-mdc-form-field-icon-prefix {
    margin-left: 8px;
  }

  :has(.mat-mdc-form-field-icon-prefix) .mdc-floating-label {
    margin-left: 4px
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing
  {
    border-width: 2px;
  }

// TODO: Remove this once Angular defect https://github.com/angular/components/issues/28404 is fixed
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch {
    border-left-color: transparent;
  }

  .mat-mdc-form-field-error-wrapper {
    padding: 0 0 0 1em;
  }

  .mat-mdc-form-field-error {
    font-size: 90%;
    padding-bottom: 4px;
    text-align: left;
  }
}

// allow mat-form-field to sit side-by-side
.horizontal-field-group mat-form-field.mat-mdc-form-field {
  display: inline-block;
}

.mdc-checkbox {
  padding: 8px !important;
  --mdc-checkbox-selected-checkmark-color: #FFFFFF;

  .mdc-checkbox__background {
    top: inherit !important;
    left: inherit !important;
  }
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: rgba(0, 0, 0, .87);
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
  background: mat.get-color-from-palette($nbs-accent, 500);
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: $nbs-h4;
}

.mat-mdc-slide-toggle {

  .mdc-switch__handle-track .mdc-switch__handle {
    background-color: #FFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  }

  .mdc-switch__track {
    background-color: rgba(0, 0, 0, 0.31);
  }

  &.mdc-switch--disabled {
    opacity: 1 !important;

    label {
      opacity: 0.38;
    }

    .mdc-switch__track {
      background-color: rgba(0, 0, 0, 0.12);
    }

    .mdc-switch__handle-track .mdc-switch__handle {
      background-color: #FAFAFA;
    }
  }

  &.mat-mdc-slide-toggle-checked {
    .mdc-switch__handle-track .mdc-switch__handle {
      background-color: mat.get-color-from-palette($nbs-accent, 500);
    }

    .mdc-switch__track {
      background-color: mat.get-color-from-palette($nbs-accent, 100);
    }

    &.mdc-switch--disabled {
      .mdc-switch__handle-track .mdc-switch__handle {
        background-color: mat.get-color-from-palette($nbs-accent, 200);
      }

      .mdc-switch__track {
        background-color: mat.get-color-from-palette($nbs-accent, 50);
      }
    }
  }
}

mat-radio-button.mat-mdc-radio-button {
  width: 100%;

  .mdc-radio {
    padding: 8px;
  }
}

.mat-mdc-slider {
  margin-left: 16px !important;
}

// Native <select /> styling
// Note: for some reason using SCSS nesting doesn't work
select.mat-input-element option {
  background-color: #fff!important;
  color: rgba(0, 0, 0, 0.87)!important;
}

select.mat-input-element optgroup {
  background-color: #fff!important;
  color: rgba(0, 0, 0, 0.54)!important;
  font-style: normal!important;
}

.mat-mdc-select-panel {
  @include scrollable;
}

mat-form-field.mat-form-field-disabled .mdc-text-field--disabled{
  color: $nbs-disabled-color !important;
  background-color: $nbs-disabled-background-color !important;
}

.mat-form-field-disabled .mdc-floating-label{
  color: $nbs-disabled-color !important;
}
