@use '@angular/material' as mat;
.nbs-error-message {
  padding: 0.5em 0;
  display: block;

  mat-error {
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
    color: mat.get-color-from-palette($nbs-warn, 700);
    display: block;
    padding: 0.5em;
  }
}
