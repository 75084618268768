@use '@angular/material' as mat;
@use '../theme/color-palette';
@use '../theme/miscellaneous-colors';

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base {
  height: auto;
  align-items: flex-start;
  letter-spacing: normal;
  line-height: 36px;
  padding-left: 15px;
  padding-right: 15px;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  padding: 0px;
}

a.mdc-button mat-icon.mat-icon,
button.mdc-button mat-icon.mat-icon,
button.mdc-icon-button mat-icon.mat-icon {
  vertical-align: middle;
}

button.mdc-button span.ng-star-inserted {
  vertical-align: middle;
}

button {
  --mat-mdc-button-ripple-color: rgba(0,0,0,.1) !important;
}

a.mdc-button.mat-mdc-button,
a.mdc-button.mat-mdc-button:link,
a.mdc-button.mat-mdc-button:visited,
a.mdc-button.mat-mdc-button:active {
  color: miscellaneous-colors.$nbs-links;
  text-decoration: none;
}

a.mdc-button.mat-mdc-button:hover,
a.mdc-button.mat-mdc-button:focus {
  color: miscellaneous-colors.$nbs-links-hover;
  text-decoration: underline;
}

.mdc-icon-button.mat-primary:not(:disabled),
.mdc-button.mat-mdc-raised-button.mat-primary:not(:disabled),
.mdc-button.mdc-button.mat-mdc-unelevated-button.mat-primary:not(:disabled) {
  background-color: mat.get-color-from-palette(color-palette.$nbs-primary, 700);
  color: mat.get-contrast-color-from-palette(color-palette.$nbs-primary, 700);
}

.mdc-icon-button.mat-primary:disabled,
.mdc-button.mat-mdc-raised-button.mat-primary:disabled,
.mdc-button.mdc-button.mat-mdc-unelevated-button.mat-primary:disabled {
  color: $nbs-disabled-color;
  background-color: $nbs-disabled-background-color;
}

.mdc-button.mat-mdc-raised-button.mat-accent:not(:disabled),
.mdc-button.mat-mdc-unelevated-button.mat-accent:not(:disabled),
.mdc-button.mat-fab.mat-accent:not(:disabled),
.mdc-button.mat-mini-fab.mat-accent:not(:disabled) {
  background-color: mat.get-color-from-palette(color-palette.$nbs-accent, 700);
  color: mat.get-contrast-color-from-palette(color-palette.$nbs-accent, 700);
}

.mdc-button.mat-mdc-raised-button.mat-accent:disabled,
.mdc-button.mat-mdc-unelevated-button.mat-accent:disabled,
.mdc-button.mat-fab.mat-accent:disabled,
.mdc-button.mat-mini-fab.mat-accent:disabled {
  color: $nbs-disabled-color;
  background-color: $nbs-disabled-background-color;
}

.mdc-button.mat-accent:not(:disabled),
.mdc-icon-button.mat-accent:not(:disabled) {
  color: mat.get-color-from-palette(color-palette.$nbs-accent, 800);
}

.mdc-button.mdc-button--outlined.mat-mdc-button-base,
.mdc-button.mdc-button--outlined.mat-primary,
.mdc-button.mdc-button--outlined.mat-accent,
.mdc-button.mdc-button--outlined.mat-tertiary-button,
a.mdc-button.mat-mdc-outlined-button.mat-tertiary-button {
  border-color: miscellaneous-colors.$nbs-stroked-button-border;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .14);
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .12);
}

.mat-mdc-outlined-button.mat-primary:not(:disabled) {
  color: mat.get-color-from-palette(color-palette.$nbs-primary, 700);
}

.mat-mdc-outlined-button.mat-accent:not(:disabled) {
  color: mat.get-color-from-palette(color-palette.$nbs-accent, 700);
}

.mat-mdc-outlined-button.mat-primary:disabled {
  color: $nbs-disabled-color;
  background-color: $nbs-disabled-background-color;
}

.mat-mdc-outlined-button.mat-accent:disabled {
  color: $nbs-disabled-color;
  background-color: $nbs-disabled-background-color;
  border: 0;
  box-shadow: none;
}

a.mat-mdc-outlined-button.mat-tertiary-button:not(:disabled) {
  color: miscellaneous-colors.$nbs-links;
}

a.mat-mdc-outlined-button.mat-tertiary-button:hover:not(:disabled),
a.mat-mdc-outlined-button.mat-tertiary-button:focus:not(:disabled) {
  color: miscellaneous-colors.$nbs-links-hover;
}

nbs-button.disabled .mat-tertiary-button{
  color: $nbs-disabled-color;
}

nbs-button.disabled button[mat-stroked-button]{
  border: 0;
  box-shadow: none;
}
