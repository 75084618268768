nbs-warning-panel {
  display: block;
  border-radius: .25em;
  padding: 1em;
  background: var(--palette-warn-light-50);
  border: 1px solid var(--palette-misc-warn-border);
  color: var(--palette-warn-light-600);
  flex-direction: row;
  box-sizing: border-box;
  flex-direction: row;
  display: flex;
  place-content: stretch flex-start;
  align-items: stretch;

  &.vertically-center {
    place-content: center flex-start;
    align-items: center;
  }

  &:not(.vertically-center) > div {
    margin-top: .2em;
  }

  > mat-icon {
    margin-right: .5em;
    flex: 0 1 auto;
    box-sizing: border-box;
  }

  > div {
    flex: 1 1 0%;
    box-sizing: border-box;
  }

  nbs-button {
    button, a {
      &.mdc-button.mdc-button--outlined.mat-accent {
        background-color: #FFF;
        color: var(--palette-warn-light-600);
        border: 1px solid var(--palette-warn-light-600);
      }
    }

    button:hover:enabled,
    button:focus:enabled,
    a:hover,
    a:focus {
      &.mdc-button.mdc-button--outlined.mat-accent {
        background-color: var(--palette-warn-light-50);
        color: var(--palette-warn-light-600);
        border: 1px solid var(--palette-warn-light-600);
      }
    }
  }
}
