nbs-card-header {

  &>div {
    margin-bottom: 12px;
  }

  .mat-mdc-card-title h2 {
    margin-bottom: 0;
  }

  .mat-mdc-card-subtitle {
    margin-bottom: 0;
  }

  div > div:last-child > .right-side-content-container > * {
    margin: 0;
    margin-left: auto;
    margin-right: 6px;
    margin-top: 4px;
    padding: 0;
  }
}
