mat-row {
  min-height: 36px !important;
}

mat-row:hover,
mat-row:focus,
mat-row.row-selected {
  background-color: $nbs-table-row;
}

mat-cell {
  font-size: 12px !important;
  padding-left: 0.5em !important;
}

mat-header-cell {
  padding-left: 0.5em !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: rgba(0,0,0,.54) !important;
}

mat-footer-cell {
  padding-left: 0.5em !important;
}
