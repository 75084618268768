@use '@angular/material' as mat;

.mdc-tab.mat-mdc-tab {
  // mat-tab styling

  // always
  text-decoration: none;
  color: rgba(0, 0, 0, .54);
  min-width: 160px;

  // everything but disabled tabs
  &:not(.mat-mdc-tab-diabled) {
    opacity: 1 !important;
  }

  // active tabs
  &.mdc-tab--active {
    color: mat.get-color-from-palette($nbs-primary, 500);
  }

  // hover and focus
  &:hover:not(.mat-mdc-tab-diabled),
  &.cdk-focused {
    background: rgba(0, 0, 0, .04) !important;
    text-decoration: none;
  }
}

a.mdc-tab.mat-mdc-tab-link {
  // a.mat-tab-link styling

  // always
  text-decoration: none;
  color: rgba(0, 0, 0, .54);
  min-width: 160px;

  // everything but disabled tabs
  &:not(.mat-mdc-tab-diabled) {
    opacity: 1 !important;
  }

  // active tabs
  &.mdc-tab--active,
  &.mdc-tab--active:active,
  &.mdc-tab--active:link,
  &.mdc-tab--active:visited {
    color: mat.get-color-from-palette($nbs-primary, 500);
  }

  // hover and focus
  &:hover:not(.mat-mdc-tab-diabled),
  &:focus {
    background: rgba(0, 0, 0, .04) !important;
  }
}

mat-tab-group .mdc-tab-indicator,
.mat-mdc-tab-nav-bar .mdc-tab-indicator {
  bottom: 0px;
}

.mat-mdc-tab-header,
.mat-mdc-tab-nav-bar {
  border-bottom: 1px solid rgba(0,0,0,.12);
}

mat-tab-body {
  margin-top: .5em;
}
