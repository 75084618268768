nbs-button-options {
  display: block;

  > nbs-button .mdc-button__label::after {
    content: '▾';
    display: inline-block;
    font-size: 1.5em;
    margin-left: .5em;
    line-height: 0;
    transform: translateY(.15em);
  }
}

.nbs-button-options-list {
  background: #fff;
  border-radius: .25em;
  min-width: 100%;
  padding: .5em 0;
}

nbs-button-option {
  display: block;

  button {
    background: transparent;
    border: 0;
    box-sizing: border-box;
    color: $nbs-h4;
    cursor: pointer;
    display: block;
    font: inherit;
    width: 100%;
    outline: 0;
    padding: 1em;
    text-align: left;

    &:hover:not(:disabled), &:focus:not(:disabled), &.active:not(:disabled) {
      background: $nbs-table-row;
    }

    &:disabled {
      opacity: .3;
    }
  }
}
