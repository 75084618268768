[nbsinlineoverlaycontainer] {
  .cdk-overlay-connected-position-bounding-box {
    z-index: initial;

    .cdk-overlay-pane {
      display: block;
      position: fixed;
      z-index: 1003;
    }
  }

  .cdk-overlay-backdrop {
    position: fixed;
    z-index: 1001;
  }
}

[nbsinlineoverlayorigin].nbs-inline-overlay-origin-open {
  z-index: 1002;
  position: relative;
}

