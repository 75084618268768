@mixin scrollable {
  scrollbar-width: thin;

  &:hover::-webkit-scrollbar,
  &::-webkit-scrollbar {
    background-color: transparent;
  }

  &:hover::-webkit-scrollbar:vertical,
  &::-webkit-scrollbar:vertical {
    width: 6px !important;
  }

  &:hover::-webkit-scrollbar:horizontal,
  &::-webkit-scrollbar:horizontal {
    height: 6px !important;
  }

  &:hover::-webkit-scrollbar-track,
  &::-webkit-scrollbar-track {
    background-color: transparent;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }

  &:hover::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb {
    background-color: #CCC;
  }
}

@mixin scrollable-on-hover {
  scrollbar-width: none;

  &:hover {
    scrollbar-width: thin;
  }

  &:hover::-webkit-scrollbar,
  &::-webkit-scrollbar {
    background-color: transparent;
  }

  &::-webkit-scrollbar:vertical {
    width: 4px !important;
  }

  &::-webkit-scrollbar:horizontal {
    height: 4px !important;
  }

  &:hover::-webkit-scrollbar:vertical {
    width: 6px !important;
  }

  &:hover::-webkit-scrollbar:horizontal {
    height: 6px !important;
  }

  &:hover::-webkit-scrollbar-track,
  &::-webkit-scrollbar-track {
    background-color: transparent;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: #CCC;
  }
}
