@use '../theme/miscellaneous-colors';

nbs-fieldset {
  display: block;
  margin-top: 20px;

  fieldset {
    width: 100%;
    border: 0;
    padding: 0;
  }

  .mat-mdc-form-field .mat-mdc-text-field-wrapper {
    padding: 0px;
  }

  /* Mimick a mat-form-field */
  legend.mdc-floating-label.mat-mdc-floating-label {
    position: relative;
    display: inline-block !important;
    font-weight: 500;
    width: auto;
    bottom: 10px;
    color: miscellaneous-colors.$nbs-h2;
  }

  .nbs-fieldset-wrapper {
    margin: 0;
  }

  .nbs-fieldset-flex, .mat-form-field-appearance-outline .mat-mdc-form-field-flex {
    position: relative;
    display: inline-flex;
    align-items: baseline;
    box-sizing: border-box;
    width: 100%;
    padding: 0 0.75em;
  }

  .nbs-fieldset-flex, .mat-form-field-appearance-outline .nbs-fieldset-flex {
    padding: 1em 1em 0;
  }

  .nbs-fieldset-infix {
    padding: 0;
    flex: 1 0 100%;
    width: 100%;
  }

  .nbs-fieldset-outline {
    color: miscellaneous-colors.$nbs-h2;

    .mdc-notched-outline__notch {
      border-top-color: transparent;
    }

    .mdc-notched-outline__leading {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    .mdc-notched-outline__trailing {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  /* FieldsetSingleFormFieldDirective */

  &.nbs-fieldset-single-form-field mat-form-field {
    width: 100%;
    padding: 0;

    .mat-mdc-form-field-flex {
      padding-top: 0;
    }

    .mat-mdc-form-field-prefix,
    .mat-mdc-form-field-suffix {
      position: initial;
    }

    .mat-mdc-form-field-infix {
      border-top: 0;
    }
  }
}
