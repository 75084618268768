/* added to resolve contrast errors in unit tests */
mat-chip-row {
  font-size: 1em !important;
  overflow: hidden !important;

  mat-icon.mat-mdc-chip-remove {
    margin-left: 3.75px!important;
    margin-right: 3.75px!important;
  }

  &.add-chip {
    color: $nbs-links !important;
    background-color: white !important;
    cursor: pointer;

    &:hover, &:focus {
      color: $nbs-links-hover !important;
    }
  }

  .mdc-evolution-chip__text-label {
    white-space: normal;
    text-overflow: ellipsis;
    font-weight: 500 !important;
  }
}

.mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
.mat-mdc-standard-chip .mdc-evolution-chip__action--primary,
.mat-mdc-standard-chip .mat-mdc-chip-action-label {
  overflow: hidden !important;
}
