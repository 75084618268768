:root {
  // primary colors
  --palette-primary-50: #E1E9EC;
  --palette-primary-100: #B5C9D0;
  --palette-primary-200: #84A4B2;
  --palette-primary-300: #538092;
  --palette-primary-400: #2E657B;
  --palette-primary-500: #094A64;
  --palette-primary-600: #08435C;
  --palette-primary-700: #063A52;
  --palette-primary-800: #053248;
  --palette-primary-900: #022236;
  --palette-primary-a100: #3BA6FF;
  --palette-primary-a200: #219AFF;
  --palette-primary-a400: #088EFF;
  --palette-primary-a700: #0081ED;
  --palette-primary-contrast-50: #000000;
  --palette-primary-contrast-100: #000000;
  --palette-primary-contrast-200: #000000;
  --palette-primary-contrast-300: #000000;
  --palette-primary-contrast-400: #FFFFFF;
  --palette-primary-contrast-500: #FFFFFF;
  --palette-primary-contrast-600: #FFFFFF;
  --palette-primary-contrast-700: #FFFFFF;
  --palette-primary-contrast-800: #FFFFFF;
  --palette-primary-contrast-900: #FFFFFF;
  --palette-primary-contrast-a100: #000000;
  --palette-primary-contrast-a200: #000000;
  --palette-primary-contrast-a400: #000000;
  --palette-primary-contrast-a700: #000000;
  // accent colors
  --palette-accent-50: #EAF0E7;
  --palette-accent-100: #CBD9C3;
  --palette-accent-200: #A8C09A;
  --palette-accent-300: #85A672;
  --palette-accent-400: #6B9354;
  --palette-accent-500: #518036;
  --palette-accent-600: #4A7830;
  --palette-accent-700: #406D29;
  --palette-accent-800: #376322;
  --palette-accent-900: #275016;
  --palette-accent-a100: #85FF59;
  --palette-accent-a200: #73FF3F;
  --palette-accent-a400: #60FF26;
  --palette-accent-a700: #4EFF0C;
  --palette-accent-contrast-50: #000000;
  --palette-accent-contrast-100: #000000;
  --palette-accent-contrast-200: #000000;
  --palette-accent-contrast-300: #000000;
  --palette-accent-contrast-400: #000000;
  --palette-accent-contrast-500: #FFFFFF;
  --palette-accent-contrast-600: #FFFFFF;
  --palette-accent-contrast-700: #FFFFFF;
  --palette-accent-contrast-800: #FFFFFF;
  --palette-accent-contrast-900: #FFFFFF;
  --palette-accent-contrast-a100: #000000;
  --palette-accent-contrast-a200: #000000;
  --palette-accent-contrast-a400: #000000;
  --palette-accent-contrast-a700: #000000;
  // warn colors
  --palette-warn-50: #F5E9E8;
  --palette-warn-100: #E5C7C6;
  --palette-warn-200: #D4A2A0;
  --palette-warn-300: #C37C7B;
  --palette-warn-400: #B6605E;
  --palette-warn-500: #A94442;
  --palette-warn-600: #A23E3C;
  --palette-warn-700: #983533;
  --palette-warn-800: #8F2D2B;
  --palette-warn-900: #7E1E1D;
  --palette-warn-a100: #FF8887;
  --palette-warn-a200: #FF6E6D;
  --palette-warn-a400: #FF5554;
  --palette-warn-a700: #FF3C3A;
  --palette-warn-contrast-50: #000000;
  --palette-warn-contrast-100: #000000;
  --palette-warn-contrast-200: #000000;
  --palette-warn-contrast-300: #000000;
  --palette-warn-contrast-400: #000000;
  --palette-warn-contrast-500: #FFFFFF;
  --palette-warn-contrast-600: #FFFFFF;
  --palette-warn-contrast-700: #FFFFFF;
  --palette-warn-contrast-800: #FFFFFF;
  --palette-warn-contrast-900: #FFFFFF;
  --palette-warn-contrast-a100: #000000;
  --palette-warn-contrast-a200: #000000;
  --palette-warn-contrast-a400: #000000;
  --palette-warn-contrast-a700: #000000;
  --palette-warn-light-50: #FDF5EF;
  --palette-warn-light-600: #C14C00;
  // info colors
  --palette-info-50: #D9EDF7;
  --palette-info-600: #31708F;
  --palette-info-700: #08435C;
  // success colors
  --palette-success-50: #EAF0E7;
  --palette-success-600: #4A7830;
  --palette-success-700: #406D29;
  // miscellaneous colors
  --palette-misc-help-icon: #640929;
  --palette-misc-links: #3369B9;
  --palette-misc-links-hover: #1D509D;
  --palette-misc-h1: rgba(0, 0, 0, 0.69);
  --palette-misc-h2: rgba(0, 0, 0, 0.58);
  --palette-misc-h3: rgba(0, 0, 0, 0.69);
  --palette-misc-h4: rgba(0, 0, 0, 0.87);
  --palette-misc-h5: rgba(0, 0, 0, 0.87);
  --palette-misc-h6: rgba(0, 0, 0, 0.87);
  --palette-misc-signout: #E97420;
  --palette-misc-signout-hover: #C14C00;
  --palette-misc-warning: #8e3901;
  --palette-misc-stroked-button-border: rgba(0, 0, 0, 0.28);
  --palette-misc-basic-button-hover: rgba(0, 0, 0, .04);
  --palette-misc-success: #518036;
  --palette-misc-table-row: rgba(0, 0, 0, .04);
  // panel borders
  --palette-misc-info-border: #BCE8F1;
  --palette-misc-success-border: #CBD9C3;
  --palette-misc-warn-border: #F4DBC9;
  --palette-misc-error-border: #E5C7C6;
  // disabled contrast colors
  --palette-misc-disabled-color: #5A5F68;
  --palette-misc-disabled-background-color: #F0F1F2;
}
