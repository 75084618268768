nbs-table-filter {
  display: block;
  max-width: 70%;
  margin: 0 auto 1em auto;
}

@media screen and (max-width: 960px) {
  nbs-table-filter {
    max-width: 100%;
  }
}
