mat-card.mat-mdc-card {
  display: block;
  padding: 16px;
}

mat-card-content.mat-mdc-card-content {
  padding: 0px;
  padding-bottom: 16px;

  h3 {
    padding-bottom: 0;
    margin-bottom: 0;
    padding-top: 16px;
  }
}

.mat-mdc-card-actions {
  text-align: left;
  margin: 8px !important;
  display: auto;
}
