@use './theme/miscellaneous-colors';
@use './theme/color-palette';
@use '@angular/material' as mat;

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

h1 {
  color: miscellaneous-colors.$nbs-h1;
}

h2 {
  color: miscellaneous-colors.$nbs-h2;
}

h3 {
  color: miscellaneous-colors.$nbs-h3;
}

h4 {
  color: miscellaneous-colors.$nbs-h4;
}

h5 {
  color: miscellaneous-colors.$nbs-h5;
}

h6 {
  color: miscellaneous-colors.$nbs-h6;
}

a,
a:link,
a:visited,
a:active {
  color: miscellaneous-colors.$nbs-links;
  text-decoration: none;
}

a:hover,
a:focus {
  color: miscellaneous-colors.$nbs-links-hover;
  text-decoration: underline;
}

.text-warn {
  color: mat.get-color-from-palette(color-palette.$nbs-warn, 700);
}

.text-success {
  color: miscellaneous-colors.$nbs-success;
}
