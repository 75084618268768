mat-hint[nbsHint] {
  display: flex;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
  align-items: center;

  > .mat-icon {
    font-size: 16px;
    padding-right: .25em;
    flex: 0 1 auto;

    @media screen and (max-width: 599px) {
      padding-right: .5em;
      margin-right: .5em;
    }

    &.mat-icon-success {
      color: $nbs-success;
    }
  
    &.mat-icon-warning {
      color: $nbs-warning;
    }
  
  }

  > :not(mat-icon) {
    flex: 1 1 0%;
  }
}
