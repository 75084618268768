@use "sass:math";

@for $i from -8 through 8 {
  // margin top utility classes
  .mt#{$i} {
    margin-top: #{math.div($i, 4)}em!important;
  }

  // margin right utility classes
  .mr#{$i} {
    margin-right: #{math.div($i, 4)}em !important;
  }

  // margin bottom utility classes
  .mb#{$i} {
    margin-bottom: #{math.div($i, 4)}em !important;
  }

  // margin left utility classes
  .ml#{$i} {
    margin-left: #{math.div($i, 4)}em !important;
  }
}
