ul.csv, ul.csv-with-and {
  display: inline;
  list-style: none;
  padding: 0;

  li {
    display: inline;

    &::after {
      content: ", ";

      // Japanese punctuation (also used in Chinese for lists)
      html[lang="ja"] &, html[lang="zh"] & {
        content: "、　";
      }
      // RTL punctuation
      html[dir="rtl"] & {
        content: " ,";
      }
      // Arabic scipt (which is RTL) punctuation
      html[lang="ar"] &, html[lang="fa"] &, html[lang="ur"] & {
        content: " ،";
      }
    }

    &:last-child::after {
      content: ""!important;
    }
  }
}

ul.csv-with-and li {
  &:nth-last-child(2):not(:first-child)::after {
    // English punctuation
    html[lang="en"] & {
      content: ", and "
    }
    // Spanish punctuation
    html[lang="es"] & {
      content: ", y "
    }
    // German punctuation
    html[lang="de"] & {
      content: ", und "
    }
    // French punctuation
    html[lang="fr"] & {
      content: ", et "
    }
    // Italian punctuation
    html[lang="it"] & {
      content: ", e "
    }
  }

  &:nth-last-child(2):first-child::after {
    // English punctuation
    html[lang="en"] & {
      content: " and "
    }
    // Spanish punctuation
    html[lang="es"] & {
      content: " y "
    }
    // German punctuation
    html[lang="de"] & {
      content: " und "
    }
    // French punctuation
    html[lang="fr"] & {
      content: " et "
    }
    // Italian punctuation
    html[lang="it"] & {
      content: " e "
    }
  }
}