@use '../theme/miscellaneous-colors';
@use '../theme/color-palette';
@use '@angular/material' as mat;

nbs-button {
  a,
  a:link,
  a:visited,
  a:active,
  a:hover,
  a:focus {
    text-decoration: none;
  }

  .mat-mdc-tooltip-trigger {
    display: inline-flex;
  }

  a,
  button {
    padding: 0px;
    white-space: nowrap;

    &.icon-only {
      &.mdc-button,
      &.mat-mdc-unelevated-button,
      &.mat-mdc-raised-button,
      &.mat-mdc-outlined-button {
        padding-left: 0;
        padding-right: 0;
        min-width: 32px;
      }

      mat-spinner {
        margin: 0;
      }

      &.mdc-icon-button {
        line-height: 32px;
        width: 32px;
        height: 32px;
        margin-top: 2px;
        margin-bottom: 2px;

        &.mat-primary:not(:disabled) {
          background-color: transparent;
          color: mat.get-color-from-palette(color-palette.$nbs-primary, 900);
        }
      }
    }

    &.mdc-button.mat-basic:not(:disabled),
    &.mdc-icon-button.mat-basic:not(:disabled) {
      color: rgba(0, 0, 0, 0.6);
    }

    &.mdc-button.mat-tertiary-button:not(:disabled),
    &.mdc-icon-button.mat-tertiary-button:not(:disabled) {
      color: miscellaneous-colors.$nbs-links;
    }
  }

  .icon-text {
    margin-right: 8px;
  }

  // Hover/focus button states for Accessibility compliance
  button:hover:enabled,
  button:focus:enabled,
  a:hover,
  a:focus {
    &.mat-mdc-raised-button {
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .9);

      &.mat-primary {
        background-color: mat.get-color-from-palette(color-palette.$nbs-primary, 500);
      }

      &.mat-accent {
        background-color: mat.get-color-from-palette(color-palette.$nbs-accent, 500);
      }

      &.mat-tertiary-button {
        background-color: miscellaneous-colors.$nbs-basic-button-hover;
      }
    }

    &.mat-mdc-unelevated-button {
      &.mat-primary {
        background-color: mat.get-color-from-palette(color-palette.$nbs-primary, 500);
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .9);
      }

      &.mat-accent {
        background-color: mat.get-color-from-palette(color-palette.$nbs-accent, 500);
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .9);
      }

      &.mat-tertiary-button {
        background-color: miscellaneous-colors.$nbs-basic-button-hover;
      }

      &.mat-basic.icon-only {
        background-color: miscellaneous-colors.$nbs-basic-button-hover;
        border-radius: 50px;
      }
    }

    &.mat-mdc-outlined-button {
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .54);

      &.mat-primary {
        background-color: mat.get-color-from-palette(color-palette.$nbs-primary, 50);
        color: mat.get-color-from-palette(color-palette.$nbs-primary, 900);
      }

      &.mat-accent {
        background-color: mat.get-color-from-palette(color-palette.$nbs-accent, 50);
        color: mat.get-color-from-palette(color-palette.$nbs-accent, 900);
      }

      &.mat-tertiary-button {
        background-color: miscellaneous-colors.$nbs-basic-button-hover;
      }
    }

    &.mat-mdc-button:not(.icon-only) {
      text-decoration: underline;

      &.mat-primary {
        background-color: mat.get-color-from-palette(color-palette.$nbs-primary, 50);
      }

      &.mat-accent {
        background-color: mat.get-color-from-palette(color-palette.$nbs-accent, 50);
      }

      &.mat-tertiary-button {
        background-color: miscellaneous-colors.$nbs-basic-button-hover;
      }
    }

    &.mdc-icon-button.icon-only {
      &.mat-accent,
      &.mat-tertiary-button,
      &.mat-basic {
        background-color: miscellaneous-colors.$nbs-basic-button-hover
      }
      &.mat-primary {
        background-color: mat.get-color-from-palette(color-palette.$nbs-primary, 50);
        color: mat.get-color-from-palette(color-palette.$nbs-primary, 900);
      }
    }

    &.mat-tertiary-button {
      color: miscellaneous-colors.$nbs-links-hover;
    }
  }

  mat-spinner {
    display: inline-block !important;
    margin-left: -0.5em;
    margin-right: 0.5em;
    vertical-align: middle;
  }

  &.has-icon mat-spinner {
    margin-left: 0;
    margin-right: 12px;
  }

  &+nbs-button {
    margin-left: 0.75em;
  }

  button:not(:disabled) .help-icon {
    color: miscellaneous-colors.$nbs-help-icon;
  }

  .disable-pointer-events {
    pointer-events: none;
  }
}
