@use './theme/miscellaneous-colors';

nbs-chip-list {
  // Keep empty & disabled chip lists full height
  .empty-disabled .mat-mdc-form-field-flex {
    padding-bottom: 1em;
  }

  .mat-mdc-chip {
    overflow-wrap: anywhere;
    font-weight: 500;
    height: auto;
  }

  mat-icon.mat-mdc-chip-remove {
    color: miscellaneous-colors.$nbs-h4 !important;
    opacity: 0.8 !important;
    font-size: 150%!important;
    height: 1.125em!important;
    line-height: 1.125em;
    vertical-align: baseline;
  }

  // Keep empty chip lists the same height as ones with items
  .mat-chip-list-wrapper:not(ul) {
    min-height: 40px;
  }

  mat-form-field {
    &.mat-mdc-form-field-label-always-float label {
      padding-top: 0;
    }

    &.mat-form-field-disabled .mat-mdc-form-field-infix {
      min-height: 24px;
    }
  }

  > ul {
    list-style: none;
    line-height: 1.5em;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: -4px;

    li {
      margin: 4px;
      padding: 5px 12px;
      border-radius: 14px;
      background-color: #e0e0e0;
      color: #000000de;
      font-weight: 500;

      &::after {
        display: none;
      }

      &:active {
        box-shadow: none!important;
      }
    }
  }
  mat-form-field.mat-form-field-disabled .mdc-text-field--disabled{
    background-color: #FFFFFF;
  }
}
