$nbs-help-icon: var(--palette-misc-help-icon);
$nbs-links: var(--palette-misc-links);
$nbs-links-hover: var(--palette-misc-links-hover);
$nbs-h1: var(--palette-misc-h1);
$nbs-h2: var(--palette-misc-h2);
$nbs-h3: var(--palette-misc-h3);
$nbs-h4: var(--palette-misc-h4);
$nbs-h5: var(--palette-misc-h5);
$nbs-h6: var(--palette-misc-h6);
$nbs-signout: var(--palette-misc-signout);
$nbs-signout-hover: var(--palette-misc-signout-hover);
$nbs-warning: var(--palette-misc-warning);
$nbs-stroked-button-border: var(--palette-misc-stroked-button-border);
$nbs-basic-button-hover: var(--palette-misc-basic-button-hover);
$nbs-success: var(--palette-misc-success);
$nbs-table-row: var(--palette-misc-table-row);
$nbs-disabled-color: var(--palette-misc-disabled-color);
$nbs-disabled-background-color: var(--palette-misc-disabled-background-color);


