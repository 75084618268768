@use '@angular/material' as mat;
@use "@ag-grid-community/styles" as ag;
@import '../mixins/scrollable.scss';

$headerHeight: 44px;
// https://www.ag-grid.com/javascript-grid-themes-customising
@include ag.grid-styles((
  theme: material,
  material-primary-color: mat.get-color-from-palette($nbs-primary, 500),
  material-accent-color: mat.get-color-from-palette($nbs-accent, 500),
  data-color: #000000,
  row-hover-color: $nbs-table-row,
  header-cell-hover-background-color: transparent,
  selected-row-background-color: #eee,
  grid-size: 2px,
  row-height: 36px,
  header-height: $headerHeight,
  font-family: inherit
));

nbs-ag-grid, nbs-ag-grid-infinite-scrolling {
  display: block;

  ag-grid-angular {

    .ag-header-cell.ag-header-cell-sortable.ag-header-span-height.ag-header-span-total.ag-focus-managed {
      height: inherit !important;
      top: auto !important;
      border-top: 1px solid;
      border-top-color: #e2e2e2;
    }

    // Style filters appropriately
    .ag-menu {
      max-height: 446px;

      .ag-filter {
        > * {
          display: block;
        }

        > :not(nbs-ag-grid-select-filter) {
          margin: 1.5em 1em 0;
        }

        .select-all-option {
          font-size: 1em !important;
          padding-left: 12px;
          padding-right: 16px;
          padding-top: 8px;
          padding-bottom: 8px;;
          line-height: 3em;
        }

        .reset-filter {
          float: right;
          line-height: 3em;
          margin: 8px;
        }

        .no-matches-found{
          font-size: 1em !important;
          text-align: center;
          display: block;
          font-weight: 500;
          padding-top: 8px;
          padding-bottom: 8px;
          border-top: solid 1px #e2e2e2;
          border-bottom: solid 1px #e2e2e2;
        }

        .ag-select-text-filter-box{
          padding: 0px 12px;
          display: block;
        }

        mat-selection-list {
          padding-top: 0;
          border-top: 1px solid #e2e2e2;
          border-bottom: 1px solid #e2e2e2;
          overflow: auto;
          max-height: 240px;

          mat-list-option {
            font-size: 1em !important;
            height: 3em;

            .mat-mdc-list-item-unscoped-content.mdc-list-item__primary-text {
              padding-left: 8px !important;
              padding-right: 8px !important;
            }

            .mdc-list-item__start {
              margin-left: 12px;
              margin-right: 0;
            }
          }
        }

        nbs-ag-grid-number-filter input {
          -moz-appearance:textfield;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
          }
        }
      }
    }
    // Support rendering cells using CDK tables
    mat-cell, mat-header-cell, cdk-cell, cdk-header-cell {
      padding: 0 !important;
    }
    // Hide the AG paginator in favor of the mat-paginator
    .ag-paging-panel {
      display: none;
    }
    // Support custom center aligned columns
    .ag-cell-center {
      text-align: center;

      .ag-header-text-row > * {
        justify-content: center;
      }
    }

    // Add border to rows
    .ag-row {
      border-bottom-style: solid;
      border-color: #e2e2e2;
      border-width: 1px;
    }

    // Support custom right aligned columns
    .ag-cell-right {
      text-align: right;

      .ag-header-sort-button {
        justify-content: flex-end;
      }

      nbs-ag-grid-header-renderer .ag-header-filter-row {
        text-align: right;
        flex-direction: row;

        .filter-value {
          flex-flow: row-reverse;
        }
      }
    }

    .ag-header-cell {
      padding: 0 !important;
    }
    // Style headers
    nbs-ag-grid-header-renderer {
      $headerMatIconSize: 18px;

      display: flex;
      width: 100%;
      height: 100%;
      flex-flow: column;
      cursor: text;

      > *, .ag-header-text-row > * {
        display: flex;
        flex: 1;
        width: 100%;
        align-items: center;
      }

      .ag-header-text-row {
        > * {
          padding: 0 .5em;
        }

        .ag-header-sort-button {
          font-weight: 600 !important;
          font-size: 12px !important;
          border: 0;
          background: transparent;
          outline: 0;
          font: inherit;
          color: rgba(0,0,0,.54);
          cursor: pointer;
          height: 100%;
          width: 100%;

          &:hover {
            background: $nbs-table-row;
          }
        }
      }
      // Display icons the same size as the text
      mat-icon {
        font-size: $headerMatIconSize !important;
        height: $headerMatIconSize !important;
        width: $headerMatIconSize !important;
        // The ascending/descending icons use the arrow_right_alt icon
        &.ag-sort-ascending-icon {
          transform: rotate(-90deg) translateY(-1px); // Minor adjustment to keep it in the same position of the descending icon, due to the rotate
        }

        &.ag-sort-descending-icon {
          transform: rotate(90deg);
        }
      }

      &.sorting .ag-sort-icon {
        color: $nbs-links-hover;
      }

      &:not(.sorting) {
        .ag-sort-icon {
          opacity: 0;
        }
      }
      // Style the filters
      .ag-header-filter-row {
        border-top: 1px solid rgba(0,0,0,.12);
        font-weight: normal;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-flow: row-reverse;

        .filter-value {
          margin-right: 6px;
          display: flex;
          flex: 1 1 100%;
          align-items: center;
          text-align: left;
          overflow: hidden;

          > * {
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        .filter-button-container {
          display: flex;
          align-items: center;
        }
      }

      button, button:focus {
        box-shadow: none !important;
      }
    }
    // Hide the sort icons when not focused/hovered
    .ag-header-cell:focus nbs-ag-grid-header-renderer:not([aria-sort]), nbs-ag-grid-header-renderer:not([aria-sort]) .ag-header-text-row:hover, nbs-ag-grid-header-renderer:not([aria-sort]) .ag-header-sort-button:focus {
      .ag-sort-icon {
        opacity: 1;
        transition: opacity 350ms ease;
      }
    }

    .ag-pinned-left-cols-container, .ag-pinned-left-header {
      box-shadow: 0 1px 8px 0 rgba(0,0,0,.12);
      z-index: 2;
    }

    .ag-pinned-right-cols-container, .ag-pinned-right-header {
      box-shadow: 0 -1px 8px 0 rgba(0,0,0,.12);
      z-index: 2;
    }

    .ag-menu {
      z-index: 6;
    }

    .ag-overlay {
      z-index: 5;
      position: relative;
    }
    // Get filters to render properly
    .ag-root-wrapper.ag-layout-auto-height, &.fixed-height.force-no-results-message .ag-root-wrapper, .ag-root-wrapper.ag-layout-normal {
      position: relative;
      overflow: visible;

      .ag-popup {
        position: absolute;

        .ag-menu {
          top: auto !important;
          margin-top: $headerHeight * 1.5 !important;
          margin-left: 18px;
          position: relative;
        }
      }
    }

    .ag-selection-checkbox {
      margin-right: 8px;
    }

    .ag-header-select-all {
      align-self: start;
      margin-top: 12px;
      margin-left: 6px;
      margin-right: 6px;
    }

    // Style scrollbars
    .ag-menu, .ag-body-vertical-scroll > *, .ag-body-horizontal-scroll > *, .ag-menu .ag-filter mat-selection-list {
      @include scrollable;
    }

    .ag-body-viewport.ag-layout-normal,
    .ag-body-viewport .ag-center-cols-viewport {
      scrollbar-width: none;
      min-height: 0px !important;
    }
  }

  // Style the no results message
  nbs-ag-grid-no-results-message {
    text-align: center;
    font-weight: 500;
  }

  ag-grid-angular nbs-ag-grid-no-results-message {
    padding: 1em;
    position: absolute;
    bottom: .5em;
  }

  .no-results-external-container nbs-ag-grid-no-results-message {
    display: block;
    padding: 2em;
  }

  nbs-ag-grid-footer {
    display: block;
  }
}

nbs-ag-grid-infinite-scrolling.fixed-height {
  ag-grid-angular {
    overflow-y: auto;
    height: 75vh;

    @media screen and (max-height: 720px) {
      // For small screens, make it 100% height, excluding space for the header
      height: 90vh;
      height: calc(100vh - 60px);
    }
  }

  &.force-no-results-message ag-grid-angular {
    // mat-spinner height
    height: 100px;
    overflow-y: unset;

    .ag-pinned-left-cols-container, .ag-center-cols-container, .ag-pinned-right-cols-container {
      display: none;
    }
  }

  .ag-header {
    box-shadow: 0 1px 8px 0 rgba(0,0,0,.12);
    z-index: 3;
  }

  .ag-pinned-left-header, .ag-pinned-right-header {
    box-shadow: none;
  }
}

// Style the little popup with the column name when dragging and dropping a column to reorder it
.ag-dnd-ghost {
  background: #fff;
  color: rgba(0,0,0,.87);
  padding: .25em 1em;
  border-radius: 4px;
}

// the next 3 selectors fix an issue with ag-grid inside a mat-tab
mat-tab-body:has(ag-grid-angular) {
  overflow: visible !important;
}
mat-tab-body div:first-of-type:has(ag-grid-angular){
  overflow: visible !important;
}
div:has(> mat-tab-body):has(ag-grid-angular) {
  overflow: visible !important;
}

.view-by-information {
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 14px;
  line-height: 20px !important;
  padding: 4px 12px 4px 12px !important;
  width: fit-content;
}

