@import '../mixins/scrollable.scss';

.mat-mdc-dialog-container, .mat-mdc-card-actions {
  min-width: none;
  max-width: none;

  nbs-button {
    margin: 0 16px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.mat-mdc-dialog-container {
  max-width: none !important;
}

.mdc-dialog .mdc-dialog__surface {
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
}

.mat-mdc-dialog-content {
  @include scrollable;
  line-height: inherit !important;
  font-size: inherit !important;
  padding: 0px !important;
}

.mat-mdc-radio-button {
  display: inline-block;
  margin-bottom: 0px;
}

.mat-mdc-dialog-title nbs-button mat-icon {
  color: black;
}

@media (max-width: 480px) {
  div.nbs-dialog-sm.cdk-overlay-pane[style] {
    width: 100vw !important;
    min-width: 100vw !important;
    max-width: 100vw !important;
  }
  div.nbs-dialog-md.cdk-overlay-pane[style] {
    width: 100vw !important;
    min-width: 100vw !important;
    max-width: 100vw !important;
  }
  div.nbs-dialog-lg.cdk-overlay-pane[style] {
    width: 100vw !important;
    min-width: 100vw !important;
    max-width: 100vw !important;
  }
}

.nbs-dialog-sm {
  @media (min-width: 481px) {
    mat-dialog-container {
      width: 400px !important;
    }
  }
}

.nbs-dialog-md {
  @media (min-width: 481px) {
    mat-dialog-container {
      width: 800px !important;
    }
  }
}

.nbs-dialog-lg {
  @media (min-width: 481px) {
    mat-dialog-container {
      width: 1200px !important;
    }
  }
}
