mat-form-field nbs-copy-button {
    button {
        .mat-icon {
            display: inherit !important;
            color: rgba(0, 0, 0, 0.6);
        }
    }

    button:hover,
    button:focus {
        .mat-icon {
            color: rgba(0, 0, 0, 0.69);
        }
    }
}