nbs-success-message {
  display: block;
  color: var(--palette-success-600);
  flex-direction: row;
  box-sizing: border-box;
  flex-direction: row;
  display: flex;
  place-content: stretch flex-start;
  align-items: stretch;

  &.vertically-center {
    place-content: center flex-start;
    align-items: center;
  }

  &:not(.vertically-center) > div {
    margin-top: .2em;
  }

  > mat-icon {
    margin-right: .5em;
    flex: 0 1 auto;
    box-sizing: border-box;
  }

  > div {
    flex: 1 1 0%;
    box-sizing: border-box;
  }
}
