mat-hint[nbsWarningHint] {
  display: flex;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
  align-items: center;

  &[nbsWarningText] {
    color: $nbs-warning;
  }

  > .mat-icon {
    color: $nbs-warning;
    font-size: 16px;
    margin-right: .25em;
    flex: 0 1 auto;
  }

  > :not(mat-icon) {
    flex: 1 1 0%;
  }
}
