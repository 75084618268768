@use '@angular/material' as mat;

 .mat-mdc-snack-bar-container .mdc-snackbar__surface {
   background-color: #424242 !important;
   box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, .60);
   border-radius: 4px;
 }

 .message {
   color: mat.get-contrast-color-from-palette($nbs-primary, 800);
   text-align: center;
 }

 .snack-bar-with-action {
   color: mat.get-contrast-color-from-palette($nbs-primary, 800);
   display: flex;
   flex-wrap: wrap;
   gap: 10px;
   justify-content: flex-end;

   .message {
     align-self: center;
     margin-right: auto;
     text-align: left;
   }
 }

 .action-button {
   background-color: Transparent;
   border: 1px solid mat.get-contrast-color-from-palette($nbs-primary, 800);
   border-radius: 4px;
   color: mat.get-contrast-color-from-palette($nbs-primary, 800);
   padding: 4px;
   text-align: center;
 }

 button.action-button:hover,
 button.action-button:focus {
   color: mat.get-contrast-color-from-palette($nbs-primary, 700);
   text-decoration: underline;
 }
