@use '@angular/material' as mat;

nbs-help-icon {
  color: $nbs-help-icon;
  font-size: 1.5em;
}

.mdc-tooltip {
  font-size: 12px;
  background-color: #646464;
  color: mat.get-contrast-color-from-palette($nbs-primary, 800);
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, .60);
  padding: 0px;
  border-radius: 4px;
  max-width: 265px !important;
}

// need to override styling of inner tooltip container
.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: none;
}

.mdc-tooltip__surface {
  max-width: 265px !important;
}
