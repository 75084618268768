@use '@angular/material' as mat;
@use 'sass:map';

nbs-radio-field {
  display: inline-block;
  position: relative;
  margin: .5em 0;

  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;

    .nbs-radio-group-container {
      height: 100%;
      width: 100%;
      flex-direction: row;
      align-items: stretch;
      box-sizing: border-box;
      display: flex;
      flex: 1 1 0%;

      .mat-mdc-radio-button {
        display: inline-block;
        margin-bottom: 0px;
      }
    }
  }

  .legend {
    color: map.get($themeForeground, text);
    padding-right: 48px;
    word-break: break-all;
    padding-top: 6px;
  }

  &.mat-warn {
    color: mat.get-color-from-palette($nbs-warn, 500);

    .legend {
      color: mat.get-color-from-palette($nbs-warn, 500);
    }

    mat-radio-button:not(.mdc-radio--disabled) .mdc-radio__outer-circle {
      border-color: mat.get-color-from-palette($nbs-warn, 500);
    }
  }

  @mixin horizontalRadios {
    width: 100%;

    fieldset {
      flex-direction: column;
      place-content: stretch space-around;

      .legend {
        padding: 0 0 1em;
      }

      .nbs-radio-group-container {
        flex-direction: row;

        .mat-mdc-radio-button {
          width: auto;
          margin-right: 1.5em;
        }
      }
    }
  }

  @media screen and (min-width: 960px) {
    &.horizontal {
      @include horizontalRadios;
    }
  }

  @media screen and (max-width: 961px) {
    &.horizontal-small {
      @include horizontalRadios;
    }
  }
}
